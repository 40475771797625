import { LinkTo } from '@ember/routing';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import t from 'ember-intl/helpers/t';
import type IntlService from 'ember-intl/services/intl';
import type NotifyService from 'ember-notify/services/notify';
import media from 'ember-responsive/helpers/media';
import RouteTask from 'ember-route-template';
import Card from 'ember-smily-base/components/card';
import Form from 'ember-smily-base/components/form';
import IconText from 'ember-smily-base/components/icon-text';
import List from 'ember-smily-base/components/list';
import LoadingButton from 'ember-smily-base/components/loading-button';
import TopbarActions from 'ember-smily-base/components/topbar/actions';
import { callAction, createChangeset } from 'ember-smily-base/utils/model';
import type { ModelFor } from 'ember-smily-base/utils/routing';
import and from 'ember-truth-helpers/helpers/and';
import TasksEventSourceCard from 'smily-admin-ui/components/tasks/event-source-card';
import TasksFiles from 'smily-admin-ui/components/tasks/files';
import TasksTaskCard from 'smily-admin-ui/components/tasks/task/card';
import TasksTemplateFormTaskSections from 'smily-admin-ui/components/tasks/template/form-task-sections';
import type TasksTaskMangeRoute from 'smily-admin-ui/routes/tasks/task/manage';
import type CacheService from 'smily-admin-ui/services/cache';
import { getFileSrcs } from 'smily-admin-ui/templates/tasks/task/index';
import { refreshRoute } from 'smily-admin-ui/utils/routing';
import { isBookingSource } from 'smily-admin-ui/utils/tasks';

const FormSubmitButton: typeof LoadingButton<true> = LoadingButton;

interface TasksTaskManageSignature {
  Args: {
    model: ModelFor<TasksTaskMangeRoute>;
  };
}

class TasksTaskManage extends Component<TasksTaskManageSignature> {
  @service cache!: CacheService;
  @service intl!: IntlService;
  @service notify!: NotifyService;
  @service router!: RouterService;

  changeset = createChangeset(this.args.model);

  save = task({ drop: true }, async () => {
    await callAction(this.changeset, 'manage', {
      data: {
        type: 'task-management-tasks',
        id: this.changeset.get('id'),
        attributes: {
          name: this.changeset.get('name'),
          description: this.changeset.get('description'),
          category: this.changeset.get('category'),
          priority: this.changeset.get('priority'),
          'assignee-id': this.changeset.get('assignee.id'),
          status: this.changeset.get('status'),
          'expected-duration-in-minutes': this.changeset.get(
            'expectedDurationInMinutes',
          ),
          'due-at': this.changeset.get('dueAt').serialize(),
          'task-items': JSON.parse(this.changeset.get('taskItems')),
        },
      },
    });

    const id = this.changeset.id as string;

    await refreshRoute(this, 'tasks.task');
    this.router.transitionTo('tasks.task.index', id);

    this.notify.success(this.intl.t('tasks.task_form.task_updated'));
  });

  deleteTask = task({ drop: true }, async () => {
    await this.args.model.destroyRecord();
    this.router.transitionTo('tasks.index');
    this.notify.success(this.intl.t('tasks.task_form.task_deleted'));

    if (this.cache.taskCount) {
      this.cache.taskCount -= 1;
    }
  });

  deleteFile = task({ drop: true }, async (fileName: string) => {
    const file = this.args.model.attachments.find(
      ({ file }) => file.original === fileName,
    )!;

    await file.destroyRecord();
  });

  <template>
    <TopbarActions>
      <div class='d-flex gap-2'>
        {{#if
          (and (isBookingSource @model.eventSource) (media 'isSmallScreen'))
        }}
          <LinkTo
            @route='bookings.booking'
            @model={{@model.eventSource.id}}
            class='btn btn-secondary'
          >
            <IconText
              @icon='info-circle'
              @text={{t 'internals.models.booking'}}
            />
          </LinkTo>

        {{/if}}

        <FormSubmitButton
          @label={{t 'common.save'}}
          @isFormSubmit={{true}}
          @isSubmittingForm={{this.save.isRunning}}
          @disabled={{this.changeset.isPristine}}
          form='task-form'
        />
      </div>
    </TopbarActions>

    <Form
      @model={{this.changeset}}
      @onSubmit={{perform this.save}}
      id='task-form'
      class='p-3'
      as |F|
    >
      <div class='gap-3 d-flex flex-column flex-lg-row'>
        <div class='flex-7 d-flex flex-column gap-3'>
          <Card>
            <div class='card-body'>
              <List @large={{true}} @sticky={{true}} @style='light' as |Item|>
                <TasksTemplateFormTaskSections
                  @form={{F}}
                  @changeset={{this.changeset}}
                  @item={{Item}}
                />
              </List>
            </div>
          </Card>

          <LoadingButton
            @action={{perform this.deleteTask}}
            @needsConfirmation={{true}}
            class='btn btn-danger align-self-lg-start'
          >
            <IconText
              @icon='trash-alt'
              @text={{t 'tasks.task_form.delete_task'}}
            />
          </LoadingButton>
        </div>

        <div class='flex-5 d-flex flex-column gap-3'>
          {{#unless (media 'isSmallScreen')}}
            <Card>
              <:content>
                <F.Section
                  @icon='sticky-note'
                  @title={{@model.name}}
                  @vertical={{true}}
                  @contentClasses='align-items-stretch'
                >
                  {{@model.description}}

                  <TasksTaskCard
                    @model={{@model}}
                    class='p-2 border rounded-3'
                  />

                  {{#if @model.attachments.length}}
                    <TasksFiles
                      @files={{getFileSrcs @model.attachments}}
                      @deleteFile={{this.deleteFile}}
                    />
                  {{/if}}
                </F.Section>
              </:content>
            </Card>
          {{/unless}}

          <TasksEventSourceCard
            @eventSource={{@model.eventSource}}
            @form={{F}}
          />
        </div>
      </div>
    </Form>
  </template>
}

export default RouteTask(TasksTaskManage);
